import React, { Component } from 'react';
import "./ScrollDown.css"
  
class ScrollDown extends Component {

    constructor(props) {
        super(props);
        
        this.arrows = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('scroll', this.onScroll);
    }

    normalize(val, min, max) {
        let normalizedVal = ( val - min ) / ( max - min );
        
        if (normalizedVal < 0) {
            return 0;
        }

        return normalizedVal;
    }

    onScroll = () => {
        let distanceToTop = this.arrows.current.getBoundingClientRect().top;
        let min = 100;
        let max = window.innerHeight;

        let normalizedValue = this.normalize(distanceToTop, min, max);

        this.arrows.current.style.opacity = `${normalizedValue}`;



    }


    render() {
        return (
            <div className='arrows' ref={this.arrows} onClick={() => window.location.href="#about"}>
                <div className='arrow' ></div>
                <div className='arrow'></div>
                <div className='arrow'></div>
            </div>
        )
    }
}

export default ScrollDown;
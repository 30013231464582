import React, { Component } from 'react';
import "./Home.css"

import ScrollDown from '../../components/scrolldown/ScrollDown';

class Home extends Component {

    constructor() {
        super();

        this.state = { i: 0, correctNameArr: "KOEN REEFMAN".split(""), nameArr: "KOEN REEFMAN".split(""), isCorrect: Array("KOEN REEFMAN".length).fill(false)};
    }

    /* Randomize array in-place using Durstenfeld shuffle algorithm */
    shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array
    }

    scramble() {
        const characters = this.shuffleArray("KOENREEFMAN".split(""));

        let index = 0;
        const newName = this.state.correctNameArr.join("").replace(/\S/g, () => characters[index++]);
        this.setState({nameArr: newName.split("")})
    }

    unscramble() {
        let newArr = this.state.nameArr;
        let correctArr = this.state.correctNameArr;
        let isCorrect = this.state.isCorrect;
        let i = this.state.i;

        if (i >= correctArr.length) {
            return;
        }

        isCorrect[i] = true;
        newArr[i] = correctArr[i];
        i++;

        this.setState({i: i, nameArr: newArr, isCorrect: isCorrect})
        setTimeout(() => this.unscramble(), 50);
        
    }

    generateText(amount) {
        const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

        const randomChar = () => chars[Math.floor(Math.random() * (chars.length - 1))];
        const randomString = length => Array.from(Array(length)).map(randomChar).join("");

        return randomString(amount)
    }

    componentDidMount() {
        this.scramble()
        setTimeout(() => this.unscramble(), 100)
    }

    render() {
        return (
            <section className='home' id="home">
                <div className='title-container'>
                    <h1 className='title' id="title">
                        { this.state.nameArr.map((letter, i) => {
                            return <span key={i} className={`letter ${this.state.isCorrect[i]}`}>{letter}</span>
                        }) }
                    </h1>
                    {/* <div className='random-letters'>
                        {this.generateText(2000)}
                    </div> */}
                </div>
                <ScrollDown/>
            </section>
        )
    }
}

export default Home;
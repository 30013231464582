import React, { Component } from "react";
import "./MousePointer.css"

// https://stackoverflow.com/a/59808185
class MousePointer extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            mouseX: 0,
            mouseY: 0,
            trailingX: 0,
            trailingY: 0,
            smallTrailingX: 0,
            smallTrailingY: 0,
        };
        
        this.pointerPos = React.createRef();
        this.pointerStyle = React.createRef();
        this.smallPointerPos = React.createRef();
        this.smallPointerStyle = React.createRef();
        
        this.animationFrame = null;
    }

    onMouseLeave = () => {
        // Mobile phone shouldn't have any pointer
        if (matchMedia('(pointer:none)').matches || matchMedia('(pointer:coarse)').matches) {
            return
        }

        this.pointerStyle.current.style.opacity = "0";
        this.smallPointerStyle.current.style.opacity = "0";
    }
    
    onMouseEnter = () => {
        // Mobile phone shouldn't have any pointer
        if (matchMedia('(pointer:none)').matches || matchMedia('(pointer:coarse)').matches) {
            return
        }
    
        this.pointerStyle.current.style.opacity = "1";
        this.smallPointerStyle.current.style.opacity = "1";
    }

    componentDidMount() {
        document.addEventListener('mousemove', this.onMouseMove);

        document.addEventListener('mouseleave', this.onMouseLeave);
        document.addEventListener('mouseenter', this.onMouseEnter);

        document.addEventListener('scroll', this.onScroll);
        
        this.originalStyle = this.pointerStyle.current.style;
        this.smallOriginalStyle = this.smallPointerStyle.current.style;
        this.changeStyle = false;

        this.moveCursor();
    }
    
    onScroll = (evt) => {
        this.mouseStyleUpdate(this.state.mouseX, this.state.mouseY);
    }
    
    mouseStyleUpdate(x, y) {
        // On hovering title, modify cursor accordingly
        let hoveredElement = document.elementFromPoint(x, y)

        if (hoveredElement != null) {

            switch (hoveredElement.tagName) {
                case "A":
                    this.pointerStyle.current.style.opacity = 0.5;
                    
                    this.smallPointerStyle.current.style.transform = "scale(1.5)"
                    
                    this.changeStyle = true;
                    this.firstSwitch = true;
                    break;

                default:
                    this.firstSwitch = false;
                    break;
            }

            switch (hoveredElement.className) {
                case "title":
                case "letter false":
                case "letter true":
                    this.pointerStyle.current.style.width = "6rem";
                    this.pointerStyle.current.style.height = "6rem";
                    this.pointerStyle.current.style.borderRadius = "50% 50% 50% 50% / 50% 65% 35% 50%";  
                    this.pointerStyle.current.style.borderWidth = "0.125rem";
                    this.pointerStyle.current.style.animation = "spin 5s linear infinite, warp 3s alternate-reverse infinite";
                    this.pointerStyle.current.style.backgroundColor = "white";

                    this.smallPointerStyle.current.style.opacity = "0";

                    this.changeStyle = true;
                    break;
                case 'koen-img':

                    this.pointerStyle.current.style.borderWidth = "0";
                    this.smallPointerStyle.current.style.opacity = ".3";

                    this.changeStyle = true;

                    break
                case 'P':
                case "arrows": 
                case "arrow": 
                case "gantt-item work open":
                case "gantt-item work closed":
                case "gantt-item-title":
                case "gantt-item school open":
                case "gantt-item school closed":
                case "linkedin-container":
                case "social-img linkedin":
                case "github-container":
                case "social-img github":
                case "login-container":
                case "social-img login":
                case "svg-logo":
                case "bullet":
                case "bullet-title":
                case "textbox-title-close":
                    this.pointerStyle.current.style.opacity = 0.5;
                    
                    this.smallPointerStyle.current.style.transform = "scale(1.5)"
                    
                    this.changeStyle = true;
                    break;
                case "menu-item":
                case "sidebar-title":
                    this.pointerStyle.current.style.opacity = 0;
                    this.changeStyle = true;
                    break;
                default:
                    if (this.firstSwitch === true) {
                        this.firstSwitch = false;
                        break;
                    }

                    if (this.changeStyle === true) {
                        this.pointerStyle.current.style = this.originalStyle;
                        this.smallPointerStyle.current.style = this.smallOriginalStyle;
                    }
            }
        }
    }

    onMouseMove = (evt) => {
        // Mobile phone shouldn't have any pointer
        if (matchMedia('(pointer:none)').matches || matchMedia('(pointer:coarse)').matches) {
            return
        }

        const { clientX, clientY } = evt;
        
        this.setState({
            mouseX: clientX,
            mouseY: clientY,
        });
        
        this.mouseStyleUpdate(clientX, clientY)
    }

    moveCursor = () => {
        const { mouseX, mouseY, trailingX, trailingY, smallTrailingX, smallTrailingY } = this.state;
        const diffX = mouseX - trailingX;
        const diffY = mouseY - trailingY;

        const smallDiffX = mouseX - smallTrailingX;
        const smallDiffY = mouseY - smallTrailingY;

        //  Number in expression is coeficient of the delay.
        this.setState({
            trailingX: trailingX + diffX / 10,
            trailingY: trailingY + diffY / 10,
            smallTrailingX: smallTrailingX + smallDiffX / 5,
            smallTrailingY: smallTrailingY + smallDiffY / 5,
        },
        () => {

        this.pointerPos.current.style.transform = `translate(calc(${trailingX}px - 50%), calc(${trailingY}px - 50%))`;
        this.smallPointerPos.current.style.transform = `translate(calc(${smallTrailingX}px - 50%), calc(${smallTrailingY}px - 50%))`;
        requestAnimationFrame(this.moveCursor);
        });
    }

    render() {
        return (
            <div className="mouse-container">
                <div className="pointer-pos" ref={this.pointerPos}>
                    <div className="pointer" ref={this.pointerStyle}/>
                </div>
                <div className="small-pointer-pos" ref={this.smallPointerPos}>
                    <div className="small-pointer" ref={this.smallPointerStyle}/>
                </div>
            </div>
        );
    }
}

export default MousePointer;
  
     
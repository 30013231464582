import React, { Component } from "react";
import "./SideBar.css";

import KoenLogo from "./images/logo.svg";
import GithubWhite from "./images/github_white.png";
import LinkedinWhite from "./images/linkedin_white.png";
import Login from "./images/lock_white.png";

class SideBar extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            opened: false
        };

        this.openHamburger = this.openHamburger.bind(this);

        this.vl = React.createRef();
        this.home = React.createRef();
        this.about = React.createRef();
        this.experience = React.createRef();
        this.projects = React.createRef();
    }

    openHamburger() {
        this.setState({
            opened: !this.state.opened
        })
    }

    scrollToTop() {
        window.scrollTo(0, 0)
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll.bind(this));
        this.handleScroll();
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll.bind(this))
    }

    // https://math.stackexchange.com/a/914843
    maptoSmallerRange(t) {
        const a = 0;
        const b = 100;
        const c = 5;
        const d = 95;

        return Math.round(c + ((d - c) / (b - a))*(t - a));
    }

    handleScroll(evt) {
        let scrollTop = window.scrollY;
        let docHeight = document.body.offsetHeight;
        let winHeight = window.innerHeight;
        let scrollPercent = scrollTop / (docHeight - winHeight);
        let scrollPercentRounded = this.maptoSmallerRange(100 - Math.round(scrollPercent * 100));

        let home = document.getElementById("home");
        let about = document.getElementById("about");
        let experience = document.getElementById("experience");
        let projects = document.getElementById("projects");

        let homeTop = (scrollTop + home.getBoundingClientRect().top) - (0.4 * winHeight);
        let aboutTop = (scrollTop + about.getBoundingClientRect().top) - (0.4 * winHeight);
        let experienceTop = (scrollTop + experience.getBoundingClientRect().top) - (0.4 * winHeight);
        let projectsTop = (scrollTop + projects.getBoundingClientRect().top) - (0.4 * winHeight);

        if (scrollTop >= homeTop && scrollTop < aboutTop) {
            this.home.current.style.color = "white"
            this.about.current.style.color = ""
            this.experience.current.style.color = ""
            this.projects.current.style.color = ""
        } else if (scrollTop >= aboutTop && scrollTop < experienceTop){
            this.home.current.style.color = ""
            this.about.current.style.color = "white"
            this.experience.current.style.color = ""
            this.projects.current.style.color = ""
        } else if (scrollTop >= experienceTop && scrollTop < projectsTop){
            this.home.current.style.color = ""
            this.about.current.style.color = ""
            this.experience.current.style.color = "white"
            this.projects.current.style.color = ""
        } else if (scrollTop >= projectsTop) {
            this.home.current.style.color = ""
            this.about.current.style.color = ""
            this.experience.current.style.color = ""
            this.projects.current.style.color = "white"
        }

        let top = scrollPercentRounded - 10;
        let bottom = scrollPercentRounded + 10;

        if (bottom >= 20 && top <= 80) {
            this.vl.current.style.background = `linear-gradient(0deg, rgba(255, 255, 255, .05) 0%, rgba(255, 255, 255, .3) ${top}%, rgba(255, 255, 255, .5) ${scrollPercentRounded}%, rgba(255, 255, 255, .3) ${bottom}%, rgba(255, 255, 255, .05) 100%)`
        }   
    }

    render() {
        return (
            <div className="sidebar">
                <div className="menu-container">
                    <div className="vl" ref={this.vl}></div>
                    <div className="vl-md"></div>
                    <div className="vl-sm"></div>

                    <a className="menu-item" href="#">&lt; <span className="sidebar-title" ref={this.home}>Home</span> /&gt;</a>
                    <a className="menu-item" href="#about">&lt; <span className="sidebar-title" ref={this.about}>About</span> /&gt;</a>
                    <a className="menu-item" href="#experience">&lt; <span className="sidebar-title" ref={this.experience}>Experience</span> /&gt;</a>
                    <a className="menu-item" href="#projects">&lt; <span className="sidebar-title" ref={this.projects}>Projects</span> /&gt;</a>
                </div>
            </div>
        );
    }
}

export default SideBar;
import React, { Component } from 'react';
import "./CardSelector.css"
  
class CardSelector extends Component {

    state = {
        'opened' : false,
        'style_one' : null,
        'style_two' : null,
        'style_three' : null,
        'top' : 1,
    }

    constructor(props) {
        super(props);

        this.cardContainer = React.createRef();

        this.one = React.createRef();
        this.two = React.createRef();
        this.three = React.createRef();

        this.expl_one = React.createRef();
        this.expl_two = React.createRef();
        this.expl_three = React.createRef();

        this.expandCards = this.expandCards.bind(this)
        this.selectTop = this.selectTop.bind(this)
        
    }

    componentDidMount() {
        this.setState({
            "style_one" : this.one.current.style,
            "style_two" : this.two.current.style,
            "style_three" : this.three.current.style,
        });
    }

    expandCards() {
        if (!this.state.opened) {
            this.one.current.style.transform = "translateX(0%)";
            this.two.current.style.transform = "translateX(120%)";
            this.three.current.style.transform = "translateX(240%)";

            this.setState({
                "opened" : true
            });
        } else {
            let zOne = this.one.current.style.zIndex;
            this.one.current.style = this.state.style_one;
            this.one.current.style.zIndex = zOne;

            let zTwo = this.two.current.style.zIndex;
            this.two.current.style = this.state.style_two;
            this.two.current.style.zIndex = zTwo;

            let zThree = this.three.current.style.zIndex;
            this.three.current.style = this.state.style_three;
            this.three.current.style.zIndex = zThree;

            this.setState({
                "opened" : false
            });
        }
    }

    selectTop(top_idx) {
        if (this.state.opened) {
            if (this.state.top !== top_idx) {
                let old_top = this.state.top;

                this.one.current.style.zIndex = "1"
                this.two.current.style.zIndex = "1"
                this.three.current.style.zIndex = "1"

                switch(top_idx) {
                    case 1:
                        this.one.current.style.zIndex = "3"

                        this.expl_one.current.style.display = "block"
                        this.expl_two.current.style.display = "none"
                        this.expl_three.current.style.display = "none"

                        break;
                    case 2:
                        this.two.current.style.zIndex = "3"

                        this.expl_one.current.style.display = "none"
                        this.expl_two.current.style.display = "block"
                        this.expl_three.current.style.display = "none"

                        break;
                    case 3:
                        this.three.current.style.zIndex = "3"

                        this.expl_one.current.style.display = "none"
                        this.expl_two.current.style.display = "none"
                        this.expl_three.current.style.display = "block"

                        break;
                }

                switch(old_top) {
                    case 1:
                        this.one.current.style.zIndex = 2
                        break;
                    case 2:
                        this.two.current.style.zIndex = 2
                        break;
                    case 3:
                        this.three.current.style.zIndex = 2
                        break;
                }

                this.setState({
                    "top" : top_idx,
                })
            }
        }
    }

    render() {
        return (
            <div className='card-container'>
                <div className='card-inner-container' ref={this.cardContainer} onClick={this.expandCards}>
                    <div className='card one' id="vanremmen" ref={this.one} onClick={() => this.selectTop(1)}><p>Van Remmen UV Technology</p><img src="https://s3.eu-central-1.amazonaws.com/huisstijlgids/afbeeldingen/20170725-Van_Remmen-Locatie_Web-2.jpg"></img></div>
                    <div className='card two' id="hellendoorn" ref={this.two} onClick={() => this.selectTop(2)}><p>Avonturenpark Hellendoorn</p><img src="https://parkreview.nl/wp-content/uploads/2020/05/IMG_0669-2-1024x768.jpg"></img></div>
                    <div className='card three' id="xenos" ref={this.three} onClick={() => this.selectTop(3)}><p>Xenos</p><img src="https://cloud.funda.nl/valentina_media/111/059/104_1440x960.jpg"></img></div>
                </div>
                <div className="card-explanations">
                    <div className='card-explanation one' ref={this.expl_one}>
                        I am currently working as a Software Engineer for <a href="https://vanremmen.nl/">Van Remmen UV Technology</a>. Since the company is quite young in terms of it's software department, I have quite a lot of responsibility for my own tasks and have no one to fall back on. On one side, this is absolutely terrifying; However, on the other side it feels great that Van Remmen has so much trust in my abilities. Some projects I have completed or am currently working on include a Windows Desktop application to create configuration files for UV Controllers and the backend of a Django web server that is used for data collection.
                        <div className='skills-container'>
                            <br/>
                            The most important skills learnt here:
                            <div className="skills-list">
                                <li className="skill-item">Requirements Engineering</li>
                                <li className="skill-item">Discipline</li>
                                <li className="skill-item">Work ethic</li>
                            </div>
                        </div>
                    </div>
                    <div className='card-explanation two' ref={this.expl_two}>
                        After Xenos, I started working a summer job at <a href="https://www.avonturenpark.nl/">Avonturenpark Hellendoorn</a>. For the few years I worked here, I was mainly responsible for preparing food and working at the cash register. Since this was a catering job, the work hours were often very long. This taught me a lot about my work ethich I've developed over the years, which has helped me achieve many things in life.
                        
                        <div className='skills-container'>
                            <br/>
                            The most important skills learnt here:
                            <div className="skills-list">
                                <li className="skill-item">Work ethic</li>
                                <li className="skill-item">Customer Service</li>
                            </div>
                        </div>
                    
                    </div>
                    <div className='card-explanation three' ref={this.expl_three}>
                        My first real job was at a <a href="https://www.xenos.nl/">Xenos</a>. At first, I was only responsible for stocking the shelves, just like any other young kid working in retail. However, I was quickly allowed to work behind the cash register and help customers. This was my first real experience with customer service, which has helped me greatly in the rest of my computer science career and life in general.
                    
                        <div className='skills-container'>
                            <br/>
                            The most important skills learnt here:
                            <div className="skills-list">
                                <li className="skill-item">Customer Service</li>
                                <li className="skill-item">Discipline</li>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CardSelector;
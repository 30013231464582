import React, { Component } from "react";

import Home from "./pages/home/Home";
import About from "./pages/about/About"

import MousePointer from "./components/mousepointer/MousePointer";
import Projects from "./pages/projects/Projects";
import Experience from "./pages/experience/Experience";
import SideBar from "./components/navigation/SideBar";
import Socials from "./components/navigation/Socials";

class Portfolio extends Component {
    render() {
        return (
            <div className="main">
                {/* <TopBar /> */}
                <MousePointer />
                <Socials />

                <SideBar />

                <div className="content">
                    <Home/>
                    <About/>
                    <Experience/>
                    <Projects/>
                </div>

            </div>
        )
    }
}

export default Portfolio;


import React, { Component } from "react";

import "@fontsource/fresca"; // Defaults to weight 400.
import "./App.css";
import Portfolio from "./portfolio/Portfolio";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import NotFound from "./404/NotFound"; 

class App extends Component {
    render() {
        return (
            <div className="App">
                <div className="container">
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Portfolio/>}/>
                        <Route path="*" element={<NotFound/>}/>
                    </Routes>
                </BrowserRouter>
                </div>
            </div>

        );
    }
}

export default App;
  
     
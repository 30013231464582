import React, { Component } from 'react';
import "./About.css";
import Tilt from "react-parallax-tilt";

import KoenPhoto from "./koen_photo.jpg"
import KoenNewPhoto from "./koen_new.jpeg"

// https://mailchimp.com/en-gb/resources/how-to-write-about-me-page/
class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            time_since_start: new Date().getFullYear() - 2013,
            age: Math.abs(new Date(new Date() - Date.parse("24 Jun 2000")).getFullYear() - 1970)
        }
    }

    render() {
        return (
            <section className='about' id="about">
            <div className='about-container'>
                <h2 className="about-title"> ABOUT ME </h2>
                <div className='about-innerContainer'>
                    <div className='about-text'>

                        I am <span>Koen Reefman</span>, a {this.state.age} year old student at the University of Twente
                        currently pursuing a master's degree in <a href="https://www.utwente.nl/en/csc/programme/specializations/software-technology/" target="_blank" rel="noreferrer">Software Technology</a>

                        <br/><br/><br/>

                        {/* I am <span>Koen Reefman</span>, a 22 year old student at the University of Twente. I have gotten my bachelor's degree in Technical 
                        Computer Science and am currently pursuing a master's degree in <a href="https://www.utwente.nl/en/csc/programme/specializations/software-technology/">Software Technology</a>. <br/><br/><br/> */}
                        
                        I have always been interested in technology and computers in general, but found my true passion for computer science 
                        during an optional class in high school. When we had to move a simple <a href="https://smallbasic-publicwebsite.azurewebsites.net/" target="_blank" rel="noreferrer">turtle</a> around our screen 
                        to create shapes, I knew this was something I would want to do for the rest of my life. <br/><br/><br/>

                        Fast forward to about {this.state.time_since_start} years after first moving the turtle around and creating circles, I am still in love with Computer Science. 
                        From programming small games for fun to creating applications for work, I love seeing my code come to life. 
                        I also enjoy exploring different sides of programming: from building websites, to native apps, to games.<br/><br/><br/>

                        Next to my love of programming, I also enjoy various different sports. I love going to the gym, taking my mountainbike for a ride, and occasionally bouldering.
                    </div>
                    <Tilt tiltMaxAngleX={5} tiltMaxAngleY={5} tiltReverse={true} perspective={1000}>
                        <div className='about-image'>
                            <img className="koen-img" src={KoenNewPhoto} alt="Koen as an angel"></img>
                        </div>
                    </Tilt>
                </div>


            </div>

            </section>
        )
    }
}
export default About;
// https://www.seanhalpin.design/about/

import React, { Component } from 'react';
import "./GanttChart.css"
  
class GanttChart extends Component {

    constructor(props) {
        super(props);   

        this.state = {
            "open-1" : false,
            "open-2" : false,
            "open-3" : false,
            "open-4" : false,
            "open-5" : false,
        }

        this.cardOne = React.createRef();
    }

    openCard(e, nr) {
        // Only open/close card when clicking the header
        if ((e.target.className !== "gantt-item-title" && !e.target.className.includes("gantt-item")) || e.target.className.includes("gantt-item-explanation")) {
            return;
        }

        switch(nr) {
            case 1:
                if (this.state['open-1'] !== true) {
                    this.setState({
                        "open-2" : false,
                        "open-3" : false,
                        "open-4" : false,
                        "open-5" : false
                    })
                };

                this.setState({
                    "open-1" : !this.state['open-1']
                });

                break;
            case 2:
                if (this.state['open-2'] !== true) {
                    this.setState({
                        "open-1" : false,
                        "open-3" : false,
                        "open-4" : false,
                        "open-5" : false
                    })
                } ;


                this.setState({
                    "open-2" : !this.state['open-2']
                });

                break;
            case 3:
                if (this.state['open-3'] !== true) {
                    this.setState({
                        "open-2" : false,
                        "open-1" : false,
                        "open-4" : false,
                        "open-5" : false
                    })
                } ;

                this.setState({
                    "open-3" : !this.state['open-3']
                });

                break;
            case 4:
                if (this.state['open-4'] !== true) {
                    this.setState({
                        "open-2" : false,
                        "open-3" : false,
                        "open-1" : false,
                        "open-5" : false
                    })
                };

                this.setState({
                    "open-4" : !this.state['open-4']
                });

                break;
            case 5:
                if (this.state['open-5'] !== true) {
                    this.setState({
                        "open-2" : false,
                        "open-3" : false,
                        "open-4" : false,
                        "open-1" : false
                    })
                };

                this.setState({
                    "open-5" : !this.state['open-5']
                });
                
                break;
            default:
                break
        }

    }

    render() {
        return (
            <div className='gantt-container'>
                <div className="gantt-chart">
                    {/* 06-2017 - 12-2018, ~1.5yrs*/}
                    <div className={'gantt-item work ' + (this.state["open-1"] ? "open" : "closed")} ref={this.cardOne} onClick={(e) => this.openCard(e, 1)}>
                        <div className='gantt-item-title'>Xenos</div>
                        <div className={'gantt-item-explanation ' + (this.state["open-1"] ? "open" : "closed")}>
                            <div className='duration'>06-2017 - 12-2018</div>
                            <div className='explanation'>
                                My first real job was at a <a href="https://www.xenos.nl/" target="_blank" rel="noreferrer">Xenos</a>. At first, I was only responsible 
                                for stocking the shelves, just like any other young kid working in retail. However, I was quickly allowed to work behind the cash 
                                register and help customers. This was my first real experience with customer service, which has benefitted me greatly in my
                                career since then.
                            </div>
                        </div>
                    </div>
                    {/* 08-2018 - 11-2021, ~3yrs */}
                    <div className={'gantt-item school ' + (this.state["open-4"] ? "open" : "closed")} ref={this.cardOne} onClick={(e) => this.openCard(e, 4)}>
                        <div className='gantt-item-title'>Technical Computer Science Bachelor</div>
                        <div className={'gantt-item-explanation ' + (this.state["open-4"] ? "open" : "closed")}>
                            <div className='duration'>08-2018 - 11-2021</div>
                            <div className='explanation'>
                                During the <a href="https://www.utwente.nl/en/education/bachelor/programmes/technical-computer-science/#impression-of-one-day-at-technical-computer-science" target="_blank" rel="noreferrer">Bachelor Technical Computer Science</a>, 
                                I learned the basics of computer science. These few years were mainly used to get knowledge on how to come up with solutions to (coding) 
                                problems and how to write clean code. This formed the basis for the rest of my Software Engineer career. <br/><br/>

                                For my <a href="http://essay.utwente.nl/86877/1/86877_Reefman_BA_EEMCS.pdf" target="_blank" rel="noreferrer">bachelor thesis</a>, I used machine learning to determine small human gestures.
                            </div>
                        </div>
                    </div>
                    {/* 07-2019 - 11-2021, ~2.5yrs */}
                    <div className={'gantt-item work ' + (this.state["open-2"] ? "open" : "closed")} ref={this.cardOne} onClick={(e) => this.openCard(e, 2)}>
                        <div className='gantt-item-title'>Avonturenpark Hellendoorn</div>
                        <div className={'gantt-item-explanation ' + (this.state["open-2"] ? "open" : "closed")}>
                            <div className='duration'>07-2019 - 11-2021</div>
                            <div className='explanation'>
                                After Xenos, I started working a summer job at <a href="https://www.avonturenpark.nl/" target="_blank" rel="noreferrer">Avonturenpark Hellendoorn</a>. 
                                For the few years I worked here, I was mainly responsible for preparing food and working at the cash register. Since this was a catering job, 
                                the work hours were often very long. This taught me a lot about my work ethich which I've developed over the years.
                            </div>
                        </div>
                    </div>
                    {/* 01-2022 - CURRENT, ~1 yr */}
                    <div className={'gantt-item work ' + (this.state["open-3"] ? "open" : "closed")} ref={this.cardOne} onClick={(e) => this.openCard(e, 3)}>
                        <div className='gantt-item-title'>Van Remmen UV Technology</div>
                        <div className={'gantt-item-explanation ' + (this.state["open-3"] ? "open" : "closed")}>
                            <div className='duration'>01-2022 - CURRENT</div>
                            <div className='explanation'>
                                I am currently working as a Software Engineer for <a href="https://vanremmen.nl/" target="_blank" rel="noreferrer">Van Remmen UV Technology</a>. 
                                Here I am responsible for all kinds of tasks; From developing a desktop application that is used to create config files for UV controllers to
                                developing the backend of a Django web server that is used for data collection.
                            </div>
                        </div>
                    </div>
                    {/* 02-2022 - CURRENT, ~1yr */}
                    <div className={'gantt-item school ' + (this.state["open-5"] ? "open" : "closed")} ref={this.cardOne} onClick={(e) => this.openCard(e, 5)}>
                        <div className='gantt-item-title'>Technical Computer Science Master</div>
                        <div className={'gantt-item-explanation ' + (this.state["open-5"] ? "open" : "closed")}>
                            <div className='duration'>02-2022 - CURRENT</div>
                            <div className='explanation'>
                                I am currently pursuing a masters degree in Software Technology, which is focussed on 
                                writing good code and all things surrounding good code (coding standards, testing, security, etc.)
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default GanttChart;
import React, { Component } from 'react';
import GanttChart from '../../components/ganttchart/GanttChart';
import "./Experience.css"

import CardSelector from '../../components/cardselector/CardSelector';
import Timeline from '../../components/timeline/Timeline';
class Experience extends Component {
    render() {
        return (
            <section className='experience' id="experience">
            <div className='experience-container'>
                <h2 className="experience-title"> EXPERIENCE </h2>
                {/* <p className="experience-text">
                    I personally think one of the most important skills of a Software Engineer is the ability to communicate; Be it with colleagues, 
                    potential clients, or stakeholders in your projects. For this reason, my experience in the retail and catering industry is quite valuable to me.
                </p> */}

                {/* <CardSelector/> */}
                {/* <GanttChart/> */}
                <Timeline />
    
            </div>
    
            </section>
        )
    }
}

export default Experience;
import React, { Component } from 'react';
import "./Projects.css"
  
class Projects extends Component {
    render() {
        return (
            <section className='projects' id="projects">
            <div className='projects-container'>
                <h2 className="projects-title"> PROJECTS </h2>
                <p className="projects-text">
                    -Coming soon-
                    {/* This website */}
                    {/* | */}
                    {/* Older projects? */}
                    {/* | */}
                    {/* Thesis? */}
                </p>
    
            </div>
    
            </section>
        )
    }
}

export default Projects;
import React, { Component } from "react";
import "./Socials.css";

import KoenLogo from "./images/logo.svg";
import GithubWhite from "./images/github_white.png";
import LinkedinWhite from "./images/linkedin_white.png";
import Login from "./images/lock_white.png";

class Socials extends Component {

    constructor(props) {
        super(props);

        this.vl = React.createRef();
    }

    selectSocial(social) {
        switch (social) {
            case "linkedin":
                this.vl.current.style.background = "linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 60%, rgba(255, 255, 255, .5) 70%, rgba(255, 255, 255, .3) 80%, rgba(255, 255, 255, 0) 100%)";
                break;
                
            case "github":
                this.vl.current.style.background = "linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 50%, rgba(255, 255, 255, .5) 60%, rgba(255, 255, 255, .3) 70%, rgba(255, 255, 255, 0) 100%)";
                break;
                
                case "login":
                this.vl.current.style.background = "linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 40%, rgba(255, 255, 255, .5) 50%, rgba(255, 255, 255, .3) 60%, rgba(255, 255, 255, 0) 100%)";
                break;
            default:
                this.vl.current.style.background = "";
                break;
        }
    }

    render() {
        return (
            <div className="socials">
                <div className="hl-social"></div>
                <div className="vl-social" ref={this.vl}></div>
                <div className="vl-social-sm" ></div>

                {/* <div className="logo"><a href="#">KR</a></div> */}
                <div className="logo"><a href="#"><img src={KoenLogo} className="svg-logo" alt="logo"></img></a></div>

                <div className="socials-container">
                    <a href="https://www.linkedin.com/in/koen-reefman" title="LinkedIn" target="_blank" rel="noreferrer" className="social linkedin-container" onMouseEnter={() => this.selectSocial('linkedin')} onMouseLeave={() => this.selectSocial()}><img className="social-img linkedin" src={LinkedinWhite} alt="LinkedIn link"></img></a>
                    <a href="https://github.com/Koen-Reefman" title="GitHub" target="_blank" rel="noreferrer" className="social github-container" onMouseEnter={() => this.selectSocial('github')} onMouseLeave={() => this.selectSocial()}><img className="social-img github" src={GithubWhite} alt="Github link"></img></a>
                    <a href="/login" title="Login" className="social login-container" onMouseEnter={() => this.selectSocial('login')} onMouseLeave={() => this.selectSocial()}><img className="social-img login" src={Login} alt="Login link"/></a>
                </div>
            </div>
        );
    }
}

export default Socials;
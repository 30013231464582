import React, { Component } from "react";
import "./Timeline.css";

class Timeline extends Component {

    constructor(props) {
        super(props);

        this.state = {
            previous: undefined,
            selected: undefined,

            xenos: {top: 0, bottom: 0},
            bachelor: {top: 0, bottom: 0},
            avonturenpark: {top: 0, bottom: 0},
            vanremmen: {top: 0, bottom: 0},
            master: {top: 0, bottom: 0}
        }
    }

    hoverBullet(item) {
        let elemDist = null;
        let containerDist = null;
        let state = this.state;

        switch (item) {
            case "xenos":
            case "bachelor":
                elemDist = document.getElementById(`bullet-title-${item}`).getBoundingClientRect().top;
                containerDist = document.getElementById("timeline-container").getBoundingClientRect().top;
                
                state[item].top = `${elemDist - containerDist + 40}px`;
                this.setState(state)
                break;
                
            case "avonturenpark":
            case "vanremmen":
            case "master":
                elemDist = document.getElementById(`bullet-title-${item}`).getBoundingClientRect().bottom;
                containerDist = document.getElementById("timeline-container").getBoundingClientRect().bottom;
        
                state[item].bottom = `${containerDist - elemDist + 40}px`;
                this.setState(state)
                break;
            default:
                break;
        }
        
        const previous = this.state.selected;
        this.setState({ previous: previous, selected: item })
    }

    clickBullet(item) {
        let elemDist = null;
        let containerDist = null;
        let state = this.state;

        switch (item) {
            case "xenos":
            case "bachelor":
                elemDist = document.getElementById(`bullet-title-${item}`).getBoundingClientRect().top;
                containerDist = document.getElementById("timeline-container").getBoundingClientRect().top;
                
                state[item].top = `${elemDist - containerDist + 40}px`;
                this.setState(state)
                break;
                
            case "avonturenpark":
            case "vanremmen":
            case "master":
                elemDist = document.getElementById(`bullet-title-${item}`).getBoundingClientRect().bottom;
                containerDist = document.getElementById("timeline-container").getBoundingClientRect().bottom;
        
                state[item].bottom = `${containerDist - elemDist + 40}px`;
                this.setState(state)
                break;
            default:
                break;
        }        
        
        if (this.state.previous === item) {
            this.setState({previous: undefined, selected: undefined})
            return;
        }

        this.setState({previous: item, selected: item})
    }

    stopHover() {
        this.setState({selected: this.state.previous})
    }

    render() {
        return (
            <div className="timeline-container" id="timeline-container">
                <div className="timeline"></div>

                <div className={"textbox left " + (this.state.selected === "xenos" ? 'active' : 'inactive')} style={{top: this.state.xenos.top}} id="xenos">
                    <div className="textbox-title">
                        <div className="textbox-title-text">
                            Xenos
                        </div>
                        <div className="textbox-title-close" onClick={() => this.clickBullet('xenos')}>
                            &#10006;
                        </div>
                    </div>
                    <div className="textbox-content">
                        In 2017, I started my first 'real' job at <a href="https://www.xenos.nl/" target="_blank" rel="noreferrer">Xenos</a>.  At first, 
                        I was only responsible for stocking the shelves, just like any other young kid working in retail. However, I was quickly allowed 
                        to work behind the cash register and help customers. This was my first real experience with customer interaction/ service, which I believe 
                        to be one of the most important skills in Software Development/ Engineering.
                    </div>
                </div>

                <div className={"textbox left " + (this.state.selected === "avonturenpark" ? 'active' : 'inactive')} style={{bottom: this.state.avonturenpark.bottom}} id="avonturenpark">
                    <div className="textbox-title">
                        <div className="textbox-title-text">
                            Avonturenpark Hellendoorn
                        </div>
                        <div className="textbox-title-close" onClick={() => this.clickBullet('avonturenpark')}>
                            &#10006;
                        </div>
                    </div>
                    <div className="textbox-content">
                        After Xenos, I started working a summer job at <a href="https://www.avonturenpark.nl/" target="_blank" rel="noreferrer">Avonturenpark Hellendoorn</a>. 
                        For the few years I worked here, I was mainly responsible for preparing food and working at the cash register. Since this was a catering job, 
                        the work hours were often very long. This taught me a lot about my work ethich which I've developed over the years.
                    </div>
                </div>

                <div className={"textbox left " + (this.state.selected === "vanremmen" ? 'active' : 'inactive')} style={{bottom: this.state.vanremmen.bottom}} id="vanremmen">
                    <div className="textbox-title">
                        <div className="textbox-title-text">
                            Van Remmen UV Technology
                        </div>
                        <div className="textbox-title-close" onClick={() => this.clickBullet('vanremmen')}>
                            &#10006;
                        </div>
                    </div>
                    <div className="textbox-content">
                        I am currently working as a Software Engineer for <a href="https://vanremmen.nl/" target="_blank" rel="noreferrer">Van Remmen UV Technology</a>. 
                        Here I am responsible for all kinds of tasks; From developing a desktop application that is used to create config files for UV controllers to
                        developing the backend of a Django web server that is used for data collection.
                    </div>
                </div>

                <div className={"textbox right " + (this.state.selected === "bachelor" ? 'active' : 'inactive')} style={{top: this.state.bachelor.top}} id="bachelor">
                    <div className="textbox-title">
                        <div className="textbox-title-text">
                            Computer Science Bachelor
                        </div>
                        <div className="textbox-title-close" onClick={() => this.clickBullet('bachelor')}>
                            &#10006;
                        </div>
                    </div>
                    <div className="textbox-content">
                        During the <a href="https://www.utwente.nl/en/education/bachelor/programmes/technical-computer-science/#impression-of-one-day-at-technical-computer-science" target="_blank" rel="noreferrer">Bachelor Technical Computer Science</a>
                        at the University of Twente, I learned the basics of computer science. These few years were mainly used to get knowledge on how to come up with solutions to (coding) 
                        problems and how to write clean code. This formed the basis for the rest of my Software Engineer career. <br/><br/>

                        For my <a href="http://essay.utwente.nl/86877/1/86877_Reefman_BA_EEMCS.pdf" target="_blank" rel="noreferrer">bachelor thesis</a>, I used machine learning to determine small human gestures.
                    </div>
                </div>

                <div className={"textbox right " + (this.state.selected === "master" ? 'active' : 'inactive')} style={{bottom: this.state.master.bottom}} id="master">
                    <div className="textbox-title">
                        <div className="textbox-title-text">
                            Computer Science Master
                        </div>
                        <div className="textbox-title-close" onClick={() => this.clickBullet('master')}>
                            &#10006;
                        </div>
                    </div>
                    <div className="textbox-content">
                        I am currently pursuing a masters degree in Software Technology, which is focussed on 
                        writing good code and all things surrounding good code (coding standards, testing, security, etc.)
                    </div>
                </div>

                <div className="year begin">2017</div>

                <div className="bullet-container xenos" onClick={() => this.clickBullet("xenos")} onMouseEnter={() => this.hoverBullet("xenos")} onMouseLeave={() => this.stopHover()}>
                    <div className={"bullet " + (this.state.selected === "xenos" ? 'active' : 'inactive')}></div>
                    <div className={"bullet-title " + (this.state.selected === "xenos" ? 'active' : 'inactive')} id="bullet-title-xenos">Xenos</div>
                </div>

                <div className="bullet-container bachelor" onClick={() => this.clickBullet("bachelor")} onMouseEnter={() => this.hoverBullet("bachelor")} onMouseLeave={() => this.stopHover()}>
                    <div className={"bullet " + (this.state.selected === "bachelor" ? 'active' : 'inactive')}></div>
                    <div className={"bullet-title " + (this.state.selected === "bachelor" ? 'active' : 'inactive')} id="bullet-title-bachelor">TCS Bachelor</div>
                </div>

                <div className="bullet-container avonturenpark" onClick={() => this.clickBullet("avonturenpark")} onMouseEnter={() => this.hoverBullet("avonturenpark")} onMouseLeave={() => this.stopHover()}>
                    <div className={"bullet " + (this.state.selected === "avonturenpark" ? 'active' : 'inactive')}></div>
                    <div className={"bullet-title " + (this.state.selected === "avonturenpark" ? 'active' : 'inactive')} id="bullet-title-avonturenpark">Avonturenpark</div>
                </div>

                <div className="bullet-container vanremmen" onClick={() => this.clickBullet("vanremmen")} onMouseEnter={() => this.hoverBullet("vanremmen")} onMouseLeave={() => this.stopHover()}>
                    <div className={"bullet " + (this.state.selected === "vanremmen" ? 'active' : 'inactive')}></div>
                    <div className={"bullet-title " + (this.state.selected === "vanremmen" ? 'active' : 'inactive')} id="bullet-title-vanremmen">Van Remmen</div>
                </div>

                <div className="bullet-container master" onClick={() => this.clickBullet("master")} onMouseEnter={() => this.hoverBullet("master")} onMouseLeave={() => this.stopHover()}>
                    <div className={"bullet " + (this.state.selected === "master" ? 'active' : 'inactive')}></div>
                    <div className={"bullet-title " + (this.state.selected === "master" ? 'active' : 'inactive')} id="bullet-title-master">TCS Master</div>
                </div>

                <div className="year end">Current</div>
            </div>
        );
    }
}

export default Timeline;
import React, { Component } from "react";
import "./NotFound.css";

import Tilt from "react-parallax-tilt";

import Minions from "./images/404_minions.png";

class NotFound extends Component {
    render() {
        return (
            <div className="notfound-container">
                <div className="notfound-text">
                    Whoops, this page doesn't exist
                </div>
                <Tilt tiltMaxAngleX={5} tiltMaxAngleY={5} tiltReverse={true} perspective={1000}>
                    <div className="notfound-minion">
                        <img className="minion-meme" src={Minions} alt="404 not found minions"/>
                    </div>
                </Tilt>
                <div className="return-text">
                    <a href="/">Return home</a>
                </div>
            </div>
        )
    }
}

export default NotFound;

